.container-product {
  flex-direction: column;
  width: 100%;
  gap: 24px;

  .section-list-product {
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;

    .section-control {
      flex-direction: column;
      width: 100%;
      flex-wrap: nowrap;
      background-color: white;
      border-radius: 8px 8px 0 0;
      padding-bottom: 12px;
      gap: 24px;

      .tabs {
        padding: 5px 24px 0;

        .ant-tabs-nav {
          margin: 0;

          &::before {
            border: none;
          }
        }
      }
    }

    .column-product {
      .ant-table-thead .ant-table-cell {
        background-color: white;
      }

      .ant-table {
        border-radius: 0 0 8px 8px;
      }

      .image-produk {
        max-width: 50px;
        height: 50px;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        border-radius: 4px;

        img {
          width: auto;
          height: 100%;
        }
      }
    }
  }
}

.container-create {
  padding: 25px 30px;
  background-color: white;
  border-radius: 8px;
  
  .section-variant {
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 24px;
    width: 100%;
    .info {
      width: 375px;
    }
  }

  .section-description {
    color: #666666;
  }

  .section-upload {
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;
    gap: 16px;

    .section-foto {
      flex-wrap: nowrap;
      align-items: start;
      gap: 24px;
    }



    .section-video {
      flex-wrap: nowrap;
      align-items: center;
      gap: 40px;
    }

    .info {
      width: 275px;
    }

    .upload-foto,
    .upload-video {
      flex-grow: 1;
      position: relative;
      .delete-video {
        text-align: center;
        position: absolute;
        right: 5px;
        top: 5px;
        width: 25px;
        border-radius: 4px;
        background-color: rgba(0,0,0,0.2);
        cursor: pointer;
        padding: 2px 0px;
        transition: all 0.1s ease-out;
        &:hover {
          background-color: red;
        }
      }
      .display-none {
        .ant-upload {
          display: none;
        }
      }

      .ant-upload-list-picture-card {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;

        &::before,
        &::after {
          display: none;
        }
      }

      .ant-upload.ant-upload-select-picture-card,
      .ant-upload-list-picture-card-container {
        width: 130px;
        height: 130px;
        border-radius: 10px;
        margin: 0;
      }

      .ant-upload-list-picture-card .ant-upload-list-item {
        border-radius: 10px;
        padding: 0;

        &::before,
        .ant-upload-span {
          width: 130px;
          height: 130px;
        }
      }

      .card-upload {
        background-color: #eeeeee;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 55px;
          height: 55px;
          object-fit: cover;
          object-position: center;
        }
      }
    }

    .upload-video {
      flex-grow: 0;

      .ant-upload-list-picture-card {
        justify-content: flex-end;
      }
    }
  }
}

.header-list {
  margin-top: 20px;
  margin-bottom: 10px;
  .icon-suffix {
    width: 17px;
    height: 17px;
    object-fit: "contain";
    cursor: pointer;
  }
}
