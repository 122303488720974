.container-setting {
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;

  .section-info {
    padding: 25px 30px;
    background-color: white;
    border-radius: 8px;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
  }

  &.store,
  &.address {
    gap: 24px;
  }

  &.edit-address {
    gap: 16px;

    .section-form {
      position: relative;

      .search-map {
        position: absolute;
        top: 85px;
        left: 45px;
        z-index: 1000;

        .input-form {
          width: 250px;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  &.account {
    flex-grow: 1;
    gap: 24px;

    .section-info {
      padding: 10px 30px 25px;
      height: 100%;

      .tabs {
        .ant-tabs-nav {
          margin: 0;

          &::before {
            border: none;
          }
        }
      }
    }

    .section-list-bank {
      flex-direction: column;
      gap: 24px;
      flex-grow: 1;

      .card-list {
        padding: 24px 45px 24px 16px;
        border-radius: 8px;
        border: 1px solid #dddddd;
        gap: 24px;
        align-items: center;
        flex-wrap: nowrap;

        img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          object-position: center;
        }

        .fa-trash-alt {
          margin-left: auto;
          font-size: 16px;
          color: #666666;
          cursor: pointer;
        }
      }

      .empty-bank {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        img {
          width: 170px;
          height: 170px;
          object-fit: cover;
          object-position: center;
        }

        h4 {
          margin: 24px 0 8px !important;
        }
      }
    }
  }

  .section-image-profile {
    flex-wrap: nowrap;
    gap: 24px;
    margin-bottom: 37px;

    .button {
      color: #ea217a;
      text-decoration: underline;
      font-weight: 700;
      cursor: pointer;
    }

    img {
      width: 132px;
      height: 132px;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
    }
  }

  .card-address {
    padding: 24px 16px;
    border-radius: 8px;
    border: 1px solid #dddddd;
    gap: 24px;
    align-items: center;
    flex-wrap: nowrap;

    .detail-store {
      max-width: 180px;
      width: 100%;
      height: 100%;
      flex-direction: column;
      flex-wrap: nowrap;
      padding-right: 24px;
      border-right: 1px solid #dddddd;
      gap: 12px;
    }

    .detail-address {
      flex-direction: column;
      flex-wrap: nowrap;
      height: 100%;
      gap: 10px;
    }
  }
}
