.container-home {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .ant-alert-info {
    padding-top: 0;
    padding-bottom: 0;
  }

  .header-title {
    font-size: 24px;
    font-weight: 700;
  }


  .card-home {
    padding: 32px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    align-items: flex-start;
    gap: 16px;

    .card-item {
      background-color: #fafafa;
      border: 1px solid #eeeeee;
      border-radius: 8px;
      padding: 10px 16px;
      gap: 16px;
      width: 192px;
      height: 96px;

      img {
        max-width: 24px;
        width: 100%;
        height: 24px;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .visual-onboarding {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    text-align: center;
    margin-top: 40px;

    img {
      width: 240px;
      height: 210px;
      object-fit: contain;
      object-position: center;
      margin-bottom: 25px;
    }

    .btn-gradient {
      width: 315px;
      margin-top: 32px;
    }
  }

  .section-produk-home {
    flex-direction: column;
    flex-wrap: nowrap;

    .column-product {
      margin-top: 16px;

      .image-produk {
        max-width: 50px;
        height: 50px;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        border-radius: 4px;

        img {
          width: auto;
          height: 100%;
        }
      }
    }
  }
}

.container-wallet {
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 24px;
  width: 100%;


  .empty-saldo {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 16px;

    img {
      width: 180px;
      height: 180px;
      object-fit: contain;
      object-position: center;
    }
  }

  .section-wallet {
    padding: 24px;
    background-color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    max-width: 475px;
    width: 100%;

    .icon-wallet {
      width: 24px;
      height: 24px;
      object-fit: cover;
      object-position: center;
    }
  }

  .section-riwayat {
    padding: 24px;
    background-color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    width: 100%;
    flex-direction: column;
    gap: 30px;

    .tabs {
      .ant-tabs-nav-list {
        width: 100%;
        justify-content: center;
      }

      .ant-tabs-tab {
        flex-grow: 1;
        justify-content: center;
        padding: 6px 0;

        .ant-tabs-tab-btn {
          font-size: 16px;
          font-weight: 700;
        }
      }
    }

    .section-list-riwayat {
      flex-direction: column;
      width: 100%;

      .card-riwayat {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;
        flex-wrap: nowrap;
        gap: 24px;

        h4 {
          line-height: 1.2;
        }

        .plus {
          color: #47ab1d;
          font-size: 20px;
          font-weight: 600;

          &::before {
            content: "+";
          }
        }

        .minus {
          color: #db0112;
          font-size: 20px;
          font-weight: 600;

          &::before {
            content: "-";
          }
        }

        .badge {
          padding: 2px 6px;
          width: fit-content;
          border-radius: 100px;
          color: white;
          font-size: 12px;
          font-weight: 400;
          gap: 0;

          &.plus {
            background-color: #47ab1d;
          }

          &.minus {
            background-color: #db0112;
          }
        }
      }
    }

    .ant-select-selector {
      width: 60px !important;
    }

    .ant-pagination {
      .ant-pagination-item,
      .ant-pagination-jump-next,
      .ant-pagination-jump-prev {
        display: none;
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        &.ant-pagination-disabled {
          button {
            color: rgba(0, 0, 0, 0.25);
          }

          &:hover {
            button {
              border-color: initial;
            }
          }
        }

        button {
          border-radius: 100px;
          color: #ea217a;
        }

        &:hover {
          button {
            border-color: #ea217a;
          }
        }
      }
    }
  }
}

.ant-notification-notice {
  padding: 12px 14px;
  border-radius: 10px;
  cursor: pointer;
  .ant-notification-notice-with-icon  {

    .ant-notification-notice-message {
      margin-left: 60px;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 0;
      line-height: 1.5;
    }
    .ant-notification-notice-description {
      margin-left: 60px;
      color: #666666;
      line-height: 1.4;
    }
  }
}

.container-notification {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 16px;
  flex-grow: 1;

  .section-notification {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    background-color: white;
    border-radius: 8px;
    height: 100%;

    .header {
      align-items: center;
      padding: 26px 20px;
      border-bottom: 1px solid #bbbbbb;
      flex-wrap: nowrap;
      gap: 24px;
      width: 100%;

      .btn-outline-gradient {
        border-radius: 100px;
        font-size: 12px;
        padding: 4px 10px;
        height: fit-content;
        width: fit-content;
      }
    }

    .wrapper-list-notification {
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;
      flex-grow: 1;
      overflow: auto;

      .list-notification {
        flex-direction: column;
        flex-wrap: nowrap;
        padding: 30px 16px;
        gap: 8px;
      }

      .notification-item {
        padding: 12px 16px;
        border: 1px solid #eeeeee;
        border-radius: 10px;
        flex-wrap: nowrap;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.02);
        gap: 12px;
        cursor: pointer;

        // &:nth-last-child(1) {
        //   margin-bottom: 30px;
        // }

        img {
          width: 70px;
          height: 70px;
          object-fit: cover;
          object-position: center;
          border-radius: 4px;
        }

        .left {
          flex-direction: column;
          width: 100%;

          .fa-info-circle {
            color: #ea217a;
            font-size: 18px;
          }

          .fa-circle {
            color: #666666;
            font-size: 4px;
          }
        }
      }
    }
  }
}
