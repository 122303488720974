.container-ulasan {
  flex-direction: column;
  width: 100%;
  flex-wrap: nowrap;
  gap: 24px;
  flex-grow: 1;

  .section-list-ulasan {
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    flex-direction: column;
    gap: 24px;
    height: 100%;

    .header-list {
      width: 100%;
      border-bottom: 1px solid #bbbbbb;
      padding: 10px 20px 20px;

      .btn-outline-gradient {
        border-radius: 100px;
        font-size: 12px;
        padding: 4px 10px;
        height: fit-content;
      }
    }
  }

  .wrapper-list {
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-grow: 1;
    height: 100%;
    width: 100%;
    gap: 24px;

    .empty-ulasan {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 16px;

      img {
        width: 240px;
        height: 240px;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .card-ulasan {
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;

    .header {
      align-items: center;
      justify-content: space-between;
      padding: 8px 32px 11px 16px;

      &.unread {
        background-color: #ffd6ed;
      }

      &.read {
        background-color: #dddddd;
      }
    }

    .content {
      flex-wrap: nowrap;
      align-items: center;
      padding: 24px 16px;
      border: 1px solid #dddddd;
      border-top: none;
      border-radius: 0 0 8px 8px;
      gap: 24px;

      a {
        color: #666666;
        font-size: 18px;
        margin-left: auto;
      }

      .info-user {
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 16px;
        border-right: 1px solid #dddddd;
        height: 125px;
        width: 350px;
        padding-right: 10px;

        .avatar-user {
          width: 40px;
          height: 40px;
          object-fit: cover;
          object-position: center;
          border-radius: 100px;
        }
      }

      .info-produk {
        flex-direction: column;
        min-width: 450px;
        width: 85%;
        gap: 8px;

        .img-produk {
          width: 84px;
          height: 76px;
          object-fit: cover;
          object-position: center;
          border-radius: 10px;
          border: 1px solid #eeeeee;
        }
      }
    }
  }
}

.badge-ulasan {
  padding: 4px 12px;
  border-radius: 100px;
  background-color: #fcae18;
  font-size: 10px;
  height: fit-content;
  color: white;
}

.container-detail-ulasan {
  flex-direction: column;
  width: 100%;
  flex-wrap: nowrap;
  gap: 24px;

  .section-info {
    flex-direction: column;
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    gap: 24px;

    .info-user {
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 16px;

      .avatar-user {
        width: 35px;
        height: 35px;
        object-fit: cover;
        object-position: center;
        border-radius: 100px;
      }
    }

    .info-produk {
      gap: 16px;

      .img-produk {
        width: 64px;
        height: 60px;
        object-fit: cover;
        object-position: center;
        border-radius: 10px;
      }
    }
  }

  .section-detail {
    flex-direction: column;
    gap: 8px;

    .ant-image {
      border-radius: 8px;
      overflow: hidden;
      width: 64px;
      height: 64px;

      .img-trouble {
        width: 100%;
        height: auto;
      }
    }
  }
}
