@import url("../../../node_modules/antd/dist/antd.css");
@import url("../../containers/home/style.scss");
@import url("../../containers/order/style.scss");
@import url("../../containers/ulasan/style.scss");
@import url("../../containers/product/style.scss");
@import url("../../containers/setting/style.scss");

body {
  font-family: "Source Sans Pro", sans-serif;
  scroll-behavior: smooth;
  background-color: #f2f4f7;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #999999;
  border-radius: 8px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.logo-with-text {
  display: flex;
  align-items: center;
  gap: 16px;

  .img-logo {
    width: 36px;
    height: 36px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 130%;
      height: 130%;
    }
  }

  .text-logo {
    font-size: 20px;
    font-weight: 900;
    line-height: 1rem;
  }
}

.text-grey {
  color: #666666 !important;
}

a, .ant-typography-primary {
  color: #ea217a;

  &:hover {
    color: #ea217a;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}


.col-right{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.icon-bank {
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
}

.btn-gradient {
  background: linear-gradient(101.15deg, #ee4c24 3.75%, #ea217a 96.16%);
  text-align: center;
  color: white;
  height: 40px;
  border-radius: 6px;
  width: 100%;
  border: none;

  &:hover,
  &:active,
  &:focus {
    background: linear-gradient(101.15deg, #ee4c24 3.75%, #ea217a 96.16%);
    color: white;
  }

  span {
    font-size: 14px;
    font-weight: 600;
  }
}

.d-flex {
  display: flex;
}

.btn-outline-gradient {
  text-align: center;
  color: #ea217a;
  height: 40px;
  border-radius: 6px;
  width: 100%;
  background-color: white;
  border: 1px solid #ea217a;
  &.btn-add{
    margin-top: 15px;
    text-align: left;
    background: linear-gradient(228.07deg, rgba(255, 186, 224, 0.05) 6.75%, rgba(254, 47, 162, 0.05) 78.67%);
  }

  &:hover,
  &:active,
  &:focus {
    background-color: white;
    border: 1px solid #ea217a;
    color: #ea217a;
  }

  span {
    font-size: 14px;
    font-weight: 600;
  }
}


.btn-plain {
  text-align: center;
  height: 40px;
  border-radius: 6px;
  width: 100%;
  background-color: white;
  border: 1px solid #bbbbbb;
  color: #666666;


  &:hover,
  &:active,
  &:focus {
    background-color: white;
    color: #666666;
    border: 1px solid #bbbbbb;
  }

  span {
    font-size: 14px;
    font-weight: 600;
  }
}

.input-form {
  border: 1px solid #d0d6dd;
  border-radius: 6px;
  padding: 8px 12px;
  width: 100%;

  &.small {
    padding: 4px 8px;
  }

  &.borderless {
    border-width: 0 0 1px;
    border-radius: 0;
    padding: 0;

    &:focus {
      box-shadow: none;
    }
  }
}

.textarea-form {
  border: 1px solid #d0d6dd;
  border-radius: 6px;
  width: 100%;
  padding: 8px 0 0 12px;
  &.ant-input-textarea-status-error{
    border-color: #ff4d4f!important;
  }
  .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
   border: none;
   box-shadow: none;
  }

  textarea {
    padding: 0;
    border: none;

    &.ant-input {
      &:focus {
        border: none;
        box-shadow: none;
      }
    }
  }
}

.label-required {
  flex-direction: row-reverse;
  position: relative;
  display: inline-flex;
  &::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1.5;
    content: "*";
}
}

.otp-auth,
.pin-auth {
  display: flex;
  justify-content: space-between;
}

.otp-time {
  display: flex;
  justify-content: center;
  color: #ea217a;
  font-size: 14px;
  font-weight: 400;
  margin-top: 12px;

  &.resend {
    cursor: pointer;
  }
}

.layout-client {
  max-width: 1440px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 20px;

  .header-sign-up {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 448px;

    .step {
      font-size: 18px;
      font-weight: 700;
    }

    .desc {
      font-size: 12px;
      font-weight: 400;
      margin: 0;
    }

    .step-sign-up {
      margin: 5px 0 10px;

      .ant-steps-icon {
        font-size: 16px;
      }

      .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: #ea217a;
      }

      .ant-steps-item-custom.ant-steps-item-process
        .ant-steps-item-icon
        > .ant-steps-icon {
        color: rgba(0, 0, 0, 0.25);
      }

      .ant-steps-item-title::after {
        height: 3px;
        border-radius: 100px;
      }

      .ant-steps-item-process
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title::after {
        background-color: #e0e0e0;
      }

      .ant-steps-item-finish
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title::after {
        background-color: #ea217a;
      }
    }
  }

  .card {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 6px;

    &.sign-in {
      width: 448px;
      padding: 32px;
      margin: 70px 20px 0;
    }

    &.sign-up {
      width: 448px;
      padding: 32px;
      margin: 0 20px;
    }

    &.otp,
    &.pin,
    &.setup-pin {
      width: 448px;
      margin: 70px 20px 0;
      align-items: center;

      .header-otp {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 16px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);

        span {
          font-size: 20px;
          font-weight: 600;
        }
      }
    }

    &.setup-pin {
      margin: 0 20px;
    }
  }
}

.modal {
  &.new-user {
    max-width: 475px;
  }

  .ant-modal-content {
    padding: 20px;
    border-radius: 8px;
    overflow: hidden;
  }
}

.modal-no-padding {
  &.reminder {
    max-width: 400px;

    .ant-modal-body {
      padding: 16px 32px 22px;

      .content-reminder {
        display: flex;
        flex-direction: column;
        align-items: center;

        .img-visual {
          width: 130px;
          height: 130px;
          object-fit: contain;
          object-position: center;
        }

        .desc {
          text-align: center;
          width: 80%;
          margin: 24px 0;
        }
      }
    }
  }

  &.penolakan {
    max-width: 400px;

    .section-list-alasan {
      border: 1px solid #eeeeee;
      border-radius: 8px;
    }

    .ant-radio-wrapper {
      margin: 0;
      padding: 18px 14px;
      border-bottom: 1px solid #eeeeee;

      &:nth-last-child(1) {
        border: none;

        span {
          &:nth-last-child(1) {
            flex-grow: 1;
          }
        }
      }
    }
  }
  .section-pastikan {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    gap: 10px;

    .fas {
      font-size: 20px;
      color: #ea217a;
    }
  }

  .section-pengiriman {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 100%;
    gap: 8px;
  }
  &.pickup {
    .section-penjemputan {
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;
      padding: 16px 24px;
      background-color: #eeeeee;
      border-radius: 8px;
      gap: 8px;

      .fas {
        font-size: 22px;
        color: #666666;
      }
    }
  }
  .section-detail-pengajuan {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    margin-top: 20px;

    .header {
      padding: 10px 0;
      align-items: center;
      justify-content: center;
      background-color: #ffd6ed;
      border-radius: 8px 8px 0 0;
    }

    .content {
      flex-wrap: nowrap;
      padding: 16px 24px;
      background-color: transparent;
      border: 1px solid #bbbbbb;
      border-top: none;
      border-radius: 0 0 8px 8px;
      gap: 24px;

      .content-pengajuan {
        display: flex;
        flex:1;
      }

      .title {
        color: #666666;
        font-size: 12px;
      }

      .description {
        color: #595959;
        font-size: 14px;
        font-weight: 600;
      }

      img {
        max-width: 50px;
        width: 100%;
        height: 50px;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &.detail-status {
    .section-steps {
      flex-direction: column;
      flex-wrap: nowrap;
      width: 100%;
      gap: 28px;

      .ant-steps-item-finish {
        .ant-steps-icon-dot {
          height: 24px;
          width: 24px;
          top: -7px;
          left: -7px;
          background-color: #ea217a;
        }

        .ant-steps-item-tail::after {
          background-color: #ea217a;
        }
      }

      .ant-steps-item-process {
        .ant-steps-icon-dot {
          height: 24px;
          width: 24px;
          top: -7px;
          left: -7px;
          background-color: #ea217a;
        }

        .ant-steps-item-description {
          font-weight: 600;
        }
      }

      .ant-steps-item-description {
        width: 50%;
        text-align: center;
        margin: 8px auto 0;
        line-height: 1.2;
        color: #666666;
      }
    }

    .section-status {
      flex-direction: column;
      width: 100%;
      gap: 20px;

      .ant-timeline {
        padding: 3px 0;
        max-height: 200px;
        overflow: auto;
      }

      .ant-timeline-item-last {
        padding: 0;
      }

      .ant-timeline-item-head-blue {
        color: #ea217a;
        border-color: #ea217a;
      }

      .ant-timeline-item-content {
        display: flex;
        justify-content: space-between;

        .date {
          max-width: 80px;
          width: 100%;
          text-align: right;
          font-size: 12px;
        }
      }
    }
  }

  &.lacak {
    .section-detail {
      flex-direction: column;
      padding: 16px 12px;
      background-color: #9e1f64;
      border-radius: 8px;
      width: 100%;

      .ant-typography,
      .ant-typography-copy {
        color: white;
      }

      .ant-divider-horizontal {
        background-color: white;
      }
    }

    .section-riwayat {
      flex-direction: column;
      width: 100%;
      gap: 16px;

      .badge {
        border-radius: 100px;
        width: fit-content;
        padding: 2px 10px;
        font-size: 12px;
        margin-bottom: 4px;
      }

      .ant-timeline {
        padding: 10px 0 0;
        max-height: 350px;
        overflow: auto;
      }

      .ant-timeline-item-last {
        padding: 0;
      }

      .ant-timeline-item-head-blue {
        color: #ea217a;
        border-color: #ea217a;
      }

      .ant-timeline-item-content {
        display: flex;
        justify-content: space-between;

        .date {
          max-width: 80px;
          width: 100%;
          text-align: right;
          font-size: 12px;
        }
      }
    }
  }

  &.penarikan {
    .ant-modal-body {
      padding: 32px 24px;
    }

    .section-content {
      flex-direction: column;
      width: 100%;

      .section-bank {
        flex-direction: column;
        margin-bottom: 32px;

        &.no-bank {
          align-items: center;
          width: 70%;
          margin: auto;
          text-align: center;
        }

        .list-bank {
          display: flex;
          flex-direction: column;
          margin-top: 16px;

          .radio-bank {
            display: flex;
            width: 100%;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: space-between;

            span {
              &:nth-last-child(1) {
                flex-grow: 1;
              }
            }
          }
        }
      }
    }
  }

  &.detail-penarikan {
    .ant-modal-body {
      padding: 32px;
    }

    .img-visual {
      width: 200px;
      height: 160px;
      object-fit: cover;
      object-position: center;
    }

    .section-rincian {
      flex-direction: column;
      width: 100%;
      padding: 16px;
      border: 1px solid #bbbbbb;
      border-radius: 8px;
      gap: 16px;
      background: url("../img/linear-penarikan.png") no-repeat;
      background-size: contain;
      background-position: bottom;
    }
  }

  &.product-variant {
    .ant-modal-body {
      padding-top: 10px;
    }

    .ant-modal-content {
      .ant-modal-header {
        box-shadow: none;
        .ant-modal-title{
          text-align: left;
          font-size: 18px;
          color: #333333;
          padding-left: 25px;
          margin-top: 15px;
        }
      }
    }

    .ant-form-item {
      margin: 0;
    }

    .small-label {
      position: absolute;
      bottom: -25px;
      color: rgba(102, 102, 102, 0.7);
      font-size: 12px;
    }

    .ant-select-selector {
      width: 100% !important;
      height: 40px !important;
      padding: 2px 10px;
    }

    .ant-select-selection-overflow {
      gap: 8px;
    }
  }

  &.update-pin {
    .ant-modal-body {
      padding-top: 60px;
    }

    .img-visual {
      width: 170px;
      height: 170px;
      object-fit: cover;
      object-position: center;
    }
  }

  .ant-modal-content {
    padding: 0;
    border-radius: 8px;
    overflow: hidden;

    .ant-modal-header {
      padding: 0;
      border: none;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);

      .ant-modal-title {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        padding: 16px 0;
      }
    }
  }
}

.small-label {
  position: absolute;
  bottom: -25px;
  color: rgba(102, 102, 102, 0.7);
  font-size: 12px;
}

.failWaybill{
  background-color: #FDED9A;
  font-size: 14px;
  color: #232933;
  padding: 12px 16px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  img {
    width: 20px;
    height: 20px;
    object-fit: "contain";
    margin-right: 7px;
  }
}
.successWaybill{
  background-color: rgb(7, 126, 73);
  color: white;
  font-size: 12px;
  padding: 0 5px;
}
.modal-content-new-user {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .visual-not-registered {
    width: 220px;
    height: 220px;
    object-fit: cover;
    object-position: center;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .desc {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin: 0;
  }
}
.range-picker-form {
  width: 100% !important;
  height: 41px !important;
}

.ant-row .ant-form-item,
.ant-row .ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;




  .ant-form-item-label {
    label {
      font-size: 16px;
      font-weight: 600;
      color: #666666;
    }
  }

  .ant-form-item-control {
    width: 100%;

  }
}

.ant-form-item {
  margin-bottom: 15px;
}

.ant-select-multiple {
  .ant-select-selector{
    overflow: hidden;
  }
  .ant-select-selection-item {
    border:none;
    background-color: transparent;
  }
}

.small-form {
  input,
  .ant-select,
  textarea {
    font-size: 12;
  }
  .ant-input-search-button,
  .ant-input-number,
  .ant-input-number-input,
  .ant-select:not(.ant-select-multiple) .ant-select-selector {
    height: 44px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 44px;
  }
}

.ant-form-item-control-input {
  min-height: 40px;
}

.ant-form-item-label {
  label {
    font-size: 14;
  }
}

.ant-row .ant-form-item:nth-last-child(1) {
  margin-bottom: 0;
}

.z-index-behind-header {
  z-index: 998;
}
.ant-form-item-explain {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  font-size: 12;
}
.ant-layout {
  // height: 100vh;
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;

  .ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 24px 30px;
    height: fit-content;
    border-bottom: 1px solid #d6d6d6;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

    .notif-badge {
      cursor: pointer;
      i {
        font-size: 24px;
        color: #666666;
      }

      sup {
        top: unset;
        bottom: -7px;
        right: 3px;
        font-size: 10px;
      }
    }
  }

  .ant-layout-sider {
    background-color: white;
    border-right: 1px solid #d6d6d6;

    .ant-layout-sider-children {
      padding: 32px 24px;
      gap: 24px;
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 80px;
      overflow-y: scroll;
      background-color: white;
      width: 245px;


      .user-info {
        gap: 15px;
        align-items: center;
        flex-wrap: nowrap;

        img {
          max-width: 50px;
          width: 100%;
          height: 50px;
          object-fit: cover;
          object-position: center;
          border-radius: 100px;
        }

        .username {
          font-size: 20px;
          font-weight: 700;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          width: 100%;
        }

        .address {
          align-items: center;
          gap: 5px;
          flex-wrap: nowrap;

          i {
            color: #ea217a;
            font-size: 12px;
          }

          .text-address {
            font-size: 14px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            width: 100%;
          }
        }
      }

      .card-saldo {
        padding: 18px 15px;
        border-radius: 10px;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
        background: url("../img/bg-linear-saldo.png") no-repeat -75px -30px;
        background-size: 150%;

        .icon-wallet {
          width: 24px;
          height: 24px;
          object-fit: contain;
          object-position: center;
        }

        .saldo {
          font-size: 14px;
          font-weight: 700;
        }
      }

      .sidebar-menu {
        .ant-menu-item-icon {
          filter: grayscale(100%);
        }

        .ant-menu-item-active {
          color: #ea217a;

          &:active {
            background-color: transparent;
          }
        }

        .ant-menu-submenu-selected,
        .ant-menu-item-selected {
          color: #ea217a;

          &:active {
            background-color: transparent;
          }

          .ant-menu-item-icon {
            filter: grayscale(0);
          }
        }

        .ant-menu-submenu,
        .ant-menu-sub {
          .ant-menu-submenu-active {
            color: #ea217a;
          }

          &.ant-menu-inline {
            background-color: #fff9fa;
          }

          &:hover {
            > .ant-menu-submenu-title,
            .ant-menu-item {
              &:hover {
                color: #ea217a;
              }
              &:active {
                background-color: transparent;
              }

              > .ant-menu-submenu-arrow {
                color: #ea217a;
              }
            }
          }
        }

        .ant-menu-item-selected {
          background-color: transparent;
          color: #ea217a;

          &::after {
            border-right: 3px solid #ea217a;
          }
        }

        .ant-menu-title-content {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  .content-scroll {
    max-height: 100%;

    .ant-layout-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 24px;
      overflow: auto;
      margin-top: 80px;
    }
  }
}

.ant-table-wrapper {
  .ant-table {
    overflow: hidden;
    border-radius: 8px;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background-color: transparent;
  }

  .ant-table-cell {
    font-weight: 600;
    font-size: 12px;
    color: #666666;
  }

  .icon-more-popover {
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e2e2e2;
    border-radius: 100px;
  }
}

.ant-table-pagination.ant-pagination {
  margin: 0;
  margin-top: 16px;
}

.ant-pagination-item {
  font-size: 14px;
}

.ant-pagination-total-text {
  margin-right: auto;
}

.ant-pagination-item-active {
  border: none;
  background-color: transparent;
}

.ant-alert {
  &.gradient-pink {
    border: none;
    border-radius: 12px;
    background: linear-gradient(101.15deg, #ee4c24 3.75%, #ea217a 96.16%);
    .ant-alert-close-icon {
      z-index: 99;
    }
    .ant-alert-close-icon .anticon-close {
      color: white;
    }
  }

  &.gradient-yellow {
    border: none;
    border-radius: 8px;
    background: linear-gradient(101.15deg, #f68121 3.75%, #fcae18 96.16%);

    .ant-alert-close-icon .anticon-close {
      color: white;
    }
  }

  &.yellow {
    width: 100%;
    border: none;
    border-radius: 8px;
    background-color: rgba(255, 213, 0, 0.4);

    span {
      color: black !important;
    }
  }

  &.green {
    width: 100%;
    border: none;
    border-radius: 8px;
    background-color: #cef2c0;

    span {
      color: black !important;
    }
  }

  &.grey {
    width: 100%;
    border: none;
    border-radius: 8px;
    background-color: #fafafa;

    span {
      color: #666666 !important;
    }
  }

  &.plain {
    width: 100%;
    border: 1px solid #dddddd;
    border-radius: 8px;
    background-color: white;

    span {
      color: black !important;
    }
  }
}

.ant-breadcrumb {
  a {
    color: #ea217a;
    opacity: 0.8;
  }

  li:last-child a {
    color: #ea217a;
    font-weight: 600;
    opacity: 1;
  }
}

.section-filter {
  width: 97%;
  display: flex;
  gap: 16px;
  .ant-form-item {
    margin-bottom: 0!important;
  }
}

.input-price {
  .ant-input-group-addon {
    padding: 2px 8px;
    background-color: #eeeeee;
    font-weight: 600;
    font-size: 12px;
    color: #999999;
    border: none;
    border-radius: 4px 0 0 4px;
  }

  .ant-input-group-addon:nth-last-child(1) {
    button {
      height: 40px;
      background-color: white;
      color: black;
      border-color: #dddddd;
      border-radius: 0 8px 8px 0 !important;
    }
  }

  .ant-input {
    border: 1px solid #eeeeee;
    border-radius: 0 4px 4px 0;
  }
}

.input-search {
  .ant-input-group .ant-input-affix-wrapper {
    height: 44px;
    border-radius: 8px 0 0 8px !important;
  }

  input {
    border: 1px solid #eeeeee;
    border-radius: 8px;
    color: #666666;
    padding: 10px 0 10px 20px;
  }

  .ant-input-search-button {
    height: 44px;
    width: 44px;
    border-radius: 0 8px 8px 0 !important;
    background-color: #eeeeee;
  }

  &.custom-button {
    .ant-input-search-button {
      width: fit-content;
      color: black;
      background-color: white;
      border-color: #dddddd;
    }
  }
}

.ant-checkbox-wrapper,
.ant-radio-wrapper {
  &:hover {
    .ant-checkbox-inner,
    .ant-radio-inner {
      border: 1px solid #ea217a !important;
    }
  }

  .ant-checkbox,
  .ant-radio {
    .ant-checkbox-inner,
    .ant-radio-inner {
      border: 1px solid #666666;

      &::after {
        background-color: #ea217a;
      }
    }

    &.ant-checkbox-checked,
    &.ant-radio-checked {
      .ant-checkbox-inner {
        background-color: #ea217a;
        border: 1px solid #ea217a;
      }

      .ant-radio-inner {
        border: 1px solid #ea217a;
      }
    }
  }
}


.select-image {
    border: 1px solid #DDDDDD;
    border-radius: 15px;
    margin: 8px 20px;
    padding:10px 20px;
    &.ant-select-item-option-selected{
      border: 1px solid #EA217A;
      background: linear-gradient(228.07deg, rgba(255, 186, 224, 0.05) 6.75%, rgba(254, 47, 162, 0.05) 78.67%);
    }

}

.select-form {

  .ant-select-selector {
    border-radius: 8px !important;
    border: 1px solid #eeeeee !important;
    width: 100% !important;
    height: 45px !important;

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      height: fit-content !important;
      margin: auto 0 !important;
    }

    .ant-select-selection-search {
      input {
        height: 100% !important;
      }
    }
  }
}

.range-picker-form {
  border: 1px solid #eeeeee;
  border-radius: 8px;
  max-width: 300px;
  width: 100%;
}

.badge {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4px 8px;
  border-radius: 8px;
}

.tabs {
  width: 100%;

  .ant-tabs > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-tab {
    &:hover,
    &:active,
    &:focus {
      color: #666666;
    }

    .ant-tabs-tab-btn {
      &:hover,
      &:active,
      &:focus {
        color: #666666;
      }
    }

    &.ant-tabs-tab-active {
      border: none;
      outline: none;

      .ant-tabs-tab-btn {
        color: #ea217a;
        font-weight: 600;
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: #ea217a;
  }
}

.ant-switch-checked {
  background-color: #ea217a!important;
}

.section-form {
  flex-direction: column;
  flex-wrap: nowrap;
  width: 95%;
  max-width: 95%;

  .form-product {
    max-width: 94%;
  }

  .form-product,
  .form-setting {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 16px;
  }

  .form-register {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .form-profile {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .form-pengiriman {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .ant-form-item-explain {
      margin-top: 0;
    }
  }

  .form-edit-setting {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;

    .input-price {
      input {
        height: 40px;
        border-left: none;
        border-radius: 0 8px 8px 0;
        text-align: left;
        padding: 2px 6px;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  input,
  .input-form,
  .textarea-form {
    border-color: #eeeeee;
  }

  textarea {
    padding-right: 10px;
  }

  .input-price {
    .ant-input-group-addon {
      width: 40px;
      background-color: white;
      border: 1px solid #eeeeee;
      border-right: none;
      border-radius: 8px 0 0 8px;
    }

    input {
      height: 40px;
      border-left: none;
      border-radius: 0 8px 8px 0;
      text-align: right;

      &:focus {
        box-shadow: none;
      }
    }
  }


  .section-input {
    flex-wrap: nowrap;
    position: relative;
    .small-label {
      position: absolute;
      bottom: -8px;
      color: rgba(102, 102, 102, 0.7);
      font-size: 12px;
    }

    &.profile {
      flex-direction: column;
    }

    .ant-form-item {
      width: 100%;
    }
  }

  .ant-select {
    width: 100%;

    .ant-select-selector {
      width: 100% !important;
      height: 45px !important;
    }
  }

  .ant-form-item .ant-form-item-label label {
    font-size: 14px;
    flex-direction: row-reverse;

    &::after {
      display: none;
    }
  }

  .label-input {
    font-weight: 600;
    color: #666666;
    display: flex;
    height: 32px;
    align-items: center;
  }

  .switch-status {
    flex-direction: column;

    .fa-info-circle {
      color: #aaaaaa;
      font-size: 16px;
      padding-left: 15px;
      cursor: pointer;
    }

    .small-label {
      bottom: 0;
    }

    .countdown-nonaktif {
      .ant-statistic-content {
        display: flex;
        height: 20px;
        align-items: center;
        overflow: hidden;
        width: 102px;
      }

      .ant-statistic-content-value {
        white-space: nowrap;
        font-size: 16px;
        font-weight: 700;
        font-family: "Source Sans Pro", sans-serif;
        color: #fe0000;
      }
    }


  }
}
.list-variant {
  gap: 16px;

  .item-variant {
    width: 100%;
    flex-wrap: nowrap;
    gap: 16px;
    align-items: center;
  }
}


.option-bank {
  align-items: center;
  gap: 24px;
  margin-top: 3px;

  img {
    width: 40px;
    height: 32px;
    object-fit: cover;
    object-position: center;
  }
}

.floater-onboarding {
  background-color: white;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 24px;
  border-radius: 8px;
  gap: 16px;
  max-width: 320px;

  .ant-row .ant-row {
    gap: 8px;

    h3 {
      line-height: 1.2;
    }

    span {
      font-size: 16px;
      line-height: 1.3;
    }
  }
}

.anticon {
  &.anticon-copy {
    color: #ea217a;
  }
}

.empty-data {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 20px;
  img{
    width: 30%;
    min-width: 120px;
    display: block;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
  }
}

.ant-spin-nested-loading {
  flex-grow: 1;

  .loading-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  .ant-spin-container {
    height: 100%;
  }
}

.markdown {
  p:last-child{
    margin-bottom: 0!important;
  }
}



#chat-widget-container {
  bottom: 10px !important;
  left: 10px !important;
}
#livechat-eye-catcher {
  display: none!important;
}
.loc-product{
  padding: 10px 25px 10px 10px;
  gap: 6px;
  width: fit-content;
  background: #FFEDF7;
  border-radius: 8px;
  .text-loc-product{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #9E1F64;
  }
}
.item-optionundefined{
  display: none;
}
.ant-popover-placement-bottom{
    top: 800px!important;
    transform-origin: 50% -4px;
    left: 830px!important;
    width: 520px;
}
.ant-popover-inner{
  border: 1px solid #8B95A5;
  border-radius: 8px;
  background: #FFFFFF;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.ant-popover-content{


}
.ant-popover-arrow{
  display: none;
}
.kabKotaPopover{
  width: 520px;
  height: 40px;
  left: 482px;
  top: 24px;

  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  border-radius: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
}